.radioknapp {
  + .skjemaelement__label:before {
    border-radius: 50%;
  }

  &:checked + .skjemaelement__label:before {
    border: 2px solid @navBla;
    box-shadow: 0 0 0 3px @white inset, 0 0 0 12px @navBla inset;
  }

  &:disabled {
    &:checked + .skjemaelement__label:before {
      border: 2px solid @navGra40;
      box-shadow: 0 0 0 3px @white inset, 0 0 0 12px @navGra40 inset;
    }
  }

  &:focus + .skjemaelement__label:before {
    box-shadow: 0 0 0 3px @fokusFarge, 0 0 0 12px @white inset;
  }

  &:checked:focus + .skjemaelement__label:before {
    .mixin-transition(200ms, box-shadow);
    box-shadow: 0 0 0 3px @fokusFarge, 0 0 0 3px @white inset, 0 0 0 12px @navBla inset;
  }
}
