.typo-sidetittel-mixin() {
  font-family: @font-family;
  font-size: 30rem / @remUnit;
  line-height: 34rem / @remUnit;
  font-weight: @fontWeightBold;

  @media(min-width: 30em) {
    font-size: 40rem / @remUnit;
    line-height: 44rem / @remUnit;
  }
}

.typo-innholdstittel-mixin() {
  font-family: @font-family;
  font-size: 24rem / @remUnit;
  font-weight: @fontWeightBold;

  @media(min-width: 30em) {
    font-size: 32rem / @remUnit;
    line-height: 36rem / @remUnit;
  }
}

.typo-systemtittel-mixin() {
  font-family: @font-family;
  font-size: 22rem / @remUnit;
  line-height: 26rem / @remUnit;
  font-weight: @fontWeightBold;

  @media(min-width: 30em) {
    font-size: 24rem / @remUnit;
    line-height: 28rem / @remUnit;
  }
}

.typo-undertittel-mixin() {
  font-family: @font-family;
  font-size: 20rem / @remUnit;
  line-height: 25rem / @remUnit;
  font-weight: @fontWeightBold;
}

.typo-ingress-mixin() {
  font-family: @font-family;
  font-size: 18rem / @remUnit;
  line-height: 24rem / @remUnit;
  font-weight: @fontWeightNormal;

  @media(min-width: 30em) {
    font-size: 20rem / @remUnit;
    line-height: 26rem / @remUnit;
  }

}

.typo-element-mixin() {
  font-family: @font-family;
  font-size: 16rem / @remUnit;
  line-height: 22rem / @remUnit;
  font-weight: @fontWeightBold;
}

.typo-normal-mixin() {
  font-family: @font-family;
  font-size: 16rem / @remUnit;
  line-height: 22rem / @remUnit;
  font-weight: 400;
}

.typo-etikett-liten-mixin() {
  font-family: @font-family;
  font-size: 14rem / @remUnit;
  line-height: 20rem / @remUnit;
  font-weight: @fontWeightNormal;
  text-transform: uppercase;
}

.typo-undertekst-bold-mixin() {
  font-family: @font-family;
  font-size: 14rem / @remUnit;
  line-height: 18rem / @remUnit;
  font-weight: @fontWeightBold;
}

.typo-undertekst-mixin() {
  font-size: 14rem / @remUnit;
  line-height: 20rem / @remUnit;
  font-family: @font-family;
  font-weight: @fontWeightNormal;
}
