@import './input-panel';

.radioPanel {
  &.inputPanel {
    &--checked {
      &:hover:not(.inputPanel--disabled) {
        background-color: rgba(0, 103, 197, 0.2);
        border: 1px solid transparent;
        box-shadow: none;

        .inputPanel__field + .inputPanel__label:before {
          background-color: @navBla;
          background-image: @checkWhite;
        }
      }
    }

    .inputPanel__label {
      &:before {
        border-radius: 50%;
      }
    }
  }
}
