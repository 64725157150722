// lesshint qualifyingElement: false
.input--xxs {
  width: 35px;
}

.input--xs {
  width: 70px;
}

.input--s {
  width: 140px;
}

.input--m {
  width: 100%;
  max-width: 210px;
}

.input--l {
  width: 100%;
  max-width: 280px;
}

.input--xl {
  width: 100%;
  max-width: 350px;
}

.input--xxl {
  width: 100%;
  max-width: 420px;
}

.input--fullbredde {
  width: 100%;
}

.skjemaelement__input() {
  appearance: none;
  padding: 0.5rem;
  background-color: @white;
  border-radius: @border-radius-base;
  border: 1px solid @navGra60;
  box-sizing: border-box;
  line-height: 1.375rem;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px @fokusFarge;
  }

  &:hover {
    border-color: @navBla;
    .mixin-transition(200ms, border-color);
  }

  &:disabled,
  &[readonly] {
    background-color: @navLysGra;
    border-color: @navGra60;
    box-shadow: none;
    color: @navMorkGra;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.skjemaelement__input {
  .skjemaelement__input();
}
