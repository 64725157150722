// Mixins
.pull-right() {
  float: right !important;
}

.pull-left() {
  float: left !important;
}

.hide() {
  display: none !important;
}

.show() {
  display: block !important;
}

.invisible() {
  visibility: hidden;
}

.ustilet() {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blokk() {
  margin-bottom: 2rem;
}

.blokk-null() {
  margin-bottom: 0;
}

.blokk-xxxs() {
  margin-bottom: 4px;
}

.blokk-xxs() {
  margin-bottom: .5rem;
}

.blokk-xs() {
  margin-bottom: 1rem;
}

.blokk-s() {
  margin-bottom: 20px;
}

.blokk-m() {
  .blokk();
}

.blokk-l() {
  margin-bottom: 2.5rem;
}

.blokk-xl() {
  margin-bottom: 4rem;
}

//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------
body {
  .clearfix {
    .clearfix();
  }

  .center-block {
    .center-block();
  }

  .pull-right {
    .pull-right();
  }

  .pull-left {
    .pull-left();
  }

  // Toggling content
  // -------------------------

  // Hide from screenreaders and browsers
  //
  // Credit: HTML5 Boilerplate

  .hide {
    .hide();
  }

  .show {
    .show();
  }

  .hidden {
    .hide();
  }

  .invisible {
    .invisible();
  }

  .text-hide {
    .text-hide();
  }

  // Gamle modig-frontend hjelpere
  .ustilet {
    .ustilet();
  }

  .blokk {
    .blokk();
  }

  .blokk-null {
    .blokk-null();
  }

  .blokk-xxxs {
    .blokk-xxxs();
  }

  .blokk-xxs {
    .blokk-xxs();
  }

  .blokk-xs {
    .blokk-xs();
  }

  .blokk-s {
    .blokk-s();
  }

  .blokk-m {
    .blokk();
  }

  .blokk-l {
    .blokk-l();
  }

  .blokk-xl {
    .blokk-xl();
  }
}