@import (reference) '~nav-frontend-core/less/_variabler';

.lenke-mixin() {
  color: @navBla;
  background: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &--hover {
    text-decoration: none;
  }

  &:focus,
  &--focus {
    outline: none;
    color: white;
    text-decoration: none;
    background-color: @fokusFarge;
    box-shadow: 0 0 0 2px @fokusFarge;
  }

  &:active,
  &--active {
    color: white;
  }
}

.lenkemedikon-mixin {
  svg {
    width: 1em;
    height: 1em;
    fill: @navBla;
    vertical-align: middle;
    position: relative;
    top: -0.125em;
  }

  svg + span:not(.sr-only),
  span:not(.sr-only) + svg {
    margin-left: 0.25em;
  }

  &:focus {
    svg {
      fill: white;
    }
  }
}
