@import (reference) '~nav-frontend-core/less/core';
@import (reference) '~nav-frontend-typografi-style/src/index';

.toggle {
  display: flex;
}

.toggle > :first-child .toggle__label {
  border-radius: @border-radius-small 0 0 @border-radius-small;
}

.toggle > :last-child .toggle__label {
  border-radius: 0 @border-radius-small @border-radius-small 0;
  border-right: 1px solid @navMorkGra;
  margin-left: -1px;
}

.toggle__label {
  .typo-normal-mixin();
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @white;
  border: 1px solid @navMorkGra;
  border-right: 0;
  color: @navMorkGra;
  cursor: pointer;
  min-width: 6.25rem;
  height: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0;
}

.toggle__label:hover {
  border-color: @navBla;
  color: @navBla;
}

.toggle__input:checked + label {
  background-color: @navDypBla;
  color: @white;
  border-color: @navDypBla;
}

.toggle__input:focus + label {
  position: relative;
  z-index: 999;
  box-shadow: 0 0 0 3px @orangeFocus;
}
