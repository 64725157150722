.selectContainer {
  position: relative;
  display: inline-block;

  select {
    padding-right: 1.5em;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    width: 100%;
  }

  select::-ms-expand {
    display: none; // Skjuler pil i IE10
  }


  // Modellert etter chevrons, men vi alt må kjøres i pseudo-elemnter ligger koden her.
  .chevronline() {
    pointer-events: none; // gjør at en kan klikke igjennom denne (pilen), slik at select åpner seg
    content: '';
    background: @navMorkGra;
    height: 2px;
    width: 10px;
    border-radius: 10px;
    right: 0.75rem;
    top: 50%;
    z-index: 4;
    position: absolute;
  }

  &:before {
    .chevronline();
    transform: translateX(3px) rotate(-45deg);
  }

  &:after {
    .chevronline();
    transform: translateX(-3px) rotate(45deg);
  }
}
