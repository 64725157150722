@import (reference) '~nav-frontend-core/less/_variabler';
@import (reference) '~nav-frontend-chevron-style/src/mixins';
@import (reference) '~nav-frontend-paneler-style/src/mixins';

.lenkepanel {
  .panel-mixin();
  .panel-focushover-mixin();
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: @navMorkGra;

  &__indikator {
    .chevron-mixin();
    .chevron-orientasjon-mixin(hoyre);
    left: 0;
    transition: left 250ms;
  }

  &:hover,
  &--hover {
    & .lenkepanel__heading {
      text-decoration: underline;
      color: @navBla;
    }

    & .lenkepanel__indikator {
      left: 6px;
    }
  }

  &--border {
    .panel-border-mixin();
    .panel-interaktiv-mixin();
  }
}
