@import 'assets/symbols';

.mixin-transition(@varighet: 150ms, @type: all) {
  transition: @type @varighet cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.checkboks,
.radioknapp {
  position: absolute;
  opacity: 0;

  + .skjemaelement__label {
    padding-left: 32px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 24px;
  }

  + .skjemaelement__label:before {
    content: '';
    position: absolute;
    display: block;
    text-align: center;
    background-color: @white;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid @navGra60;
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
  }

  &:hover + .skjemaelement__label {
    color: @navBla;

    &:before {
      border-color: @navBla;
      .mixin-transition(200ms, border-color);
      background-color: @navBlaLighten80;
    }
  }

  &:disabled {
    + .skjemaelement__label {
      color: @navGra60;

      &:hover {
        cursor: not-allowed;
      }

      &:before {
        background-color: @navLysGra;
        border-color: @navGra60;
        box-shadow: none;
      }
    }
  }
}

.checkboks--mork,
.radioknapp--mork {
  + .skjemaelement__label:before {
    border-color: @grayInactive;
  }
}

.checkboks {
  position: absolute;
  opacity: 0;

  &:focus + .skjemaelement__label:before {
    box-shadow: 0 0 0 3px @fokusFarge;
  }

  + .skjemaelement__label:before {
    border-radius: 4px;
  }

  &:checked {
    &:disabled {
      + .skjemaelement__label:before {
        border-color: @navGra40;
        background-color: @navGra40;
      }
    }

    &:focus {
      + .skjemaelement__label:before {
        background-color: white;
        background-image: @checkBlue;
      }
    }

    + .skjemaelement__label:before {
      background-image: @checkWhite;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 75%;
      border-color: @navBla;
      border-width: 2px;
      background-color: @navBla;
    }
  }
}
