.skjema__feilomrade--harFeil {
  margin-bottom: 1rem;
  background-color: @pinkErrorBg;
  box-shadow: 0 0 0 8px @pinkErrorBg;

  &:focus {
    box-shadow: 0 0 0 8px @pinkErrorBg, 0 0 0 12px @orangeFocus;
  }
}

.skjemaelement__feilmelding {
    font-style: italic;
    color: @redError;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.skjemaelement__feilmelding:empty {
  margin: 0;
}

.skjemaelement__input--harFeil {
  border-color: @redError;
  background-color: @pinkErrorBg;

  &.checkboks,
  &.radio {
    &:not(:checked) + .skjemaelement__label {
      &:before {
        border-color: @redError;
        background-color: @pinkErrorBg;
      }
    }
  }
}
