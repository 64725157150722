.panel-mixin() {
  background-color: @white;
  display: block;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: @border-radius-base;
}

.panel-focushover-mixin() {
  position: relative;

  &:hover,
  &--hover {
    box-shadow: @grayIcon 0 2px 1px 0;
  }

  &:focus,
  &--focus {
    box-shadow: 0 0 0px 3px @fokusFarge;
    outline: none;
  }
}

.panel-focus-mixin() {
  &:focus,
  &--focus {
    box-shadow: 0 0 0px 3px @fokusFarge;
    outline: none;
  }
}

.panel-border-mixin() {
  border: 1px solid @navGra20;
}

.panel-interaktiv-mixin() {
  border: 1px solid @navGra60;

  &:hover,
  &.panel-interaktiv-mixin--hover {
    border: 1px solid @navBla;
  }
}
