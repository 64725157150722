.spinner-mixin(@name, @size) {
  &--@{name} {
    height: @size;
    width: @size;
    background-size: @size @size;
  }
}

.spinner {
  display: inline-block;
  position: relative;

  animation: spinner-rotate 1.4s linear infinite;

  circle:nth-child(3) {
    animation: spinner-dasharray 1.4s ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0px;
  }

  .spinner-mixin(xxs, 16px);
  .spinner-mixin(xs, 20px);
  .spinner-mixin(s, 24px);
  .spinner-mixin(m, 32px);
  .spinner-mixin(l, 40px);
  .spinner-mixin(xl, 64px);
  .spinner-mixin(xxl, 128px);
  .spinner-mixin(xxxl, 256px);
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dasharray {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
}
