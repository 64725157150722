@import './assets/symbols';

.inputPanelGruppe .inputPanel {
  margin-bottom: 0.5rem;
}

.inputPanel {
  background-color: @white;
  border: 1px solid @navGra60;
  border-radius: 0.25rem;
  display: block;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;

  &__field {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .inputPanel__label:before {
      border: 1px solid @navBla;
      background-color: @navBla;
      background-image: @checkWhite;
    }

    &:disabled + .inputPanel__label:before {
      background-color: @navLysGra;
    }
  }

  &__label {
    .typo-normal-mixin();
    line-height: 24px;

    &:before {
      border: 1px solid @navGra60;
      border-radius: 50%;
      background-color: @white;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 75%;
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }

  &__subtext {
    .typo-normal-mixin();
    display: block;
    margin-top: 0.5rem;
  }

  &--checked {
    background-color: @navBlaLighten80;
    border: 1px solid transparent;
  }

  &--disabled {
    background-color: @navLysGra;
    box-shadow: none;

    &:hover,
    .inputPanel__field:hover,
    + .inputPanel__label:hover {
      cursor: not-allowed;
    }
  }

  &--focused {
    box-shadow: 0 0 0 3px @fokusFarge;
    border: 1px solid transparent;
  }

  &:active:not(.inputPanel--disabled):not(.bekreftCheckboksPanel) {
    background-color: @navBlaLighten80;
  }

  &:hover:not(.inputPanel--disabled):not(.bekreftCheckboksPanel) {
    border: 1px solid @navBla;
    box-shadow: @grayIcon 0 2px 1px 0;
    cursor: pointer;

    &:not(.inputPanel--checked) {
      .inputPanel__field + .inputPanel__label:before {
        background-color: @navBlaLighten60;
        border: 0;
      }
    }

    .inputPanel__field:checked + .inputPanel__label:before {
      border: 1px solid transparent;
      background-image: @checkWhite;
    }

    .inputPanel__label {
      color: @navBla;
    }
  }
}
