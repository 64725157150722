@import (reference) '~nav-frontend-core/less/_variabler';
@import (reference) '~nav-frontend-core/less/hide-text';

.lukknapp {
  .hide-text();

  position: relative;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: @navGra60;
  border: 2px solid currentColor;
  background-color: transparent;
  border-radius: @border-radius-small;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 3px;
    top: ~'calc(50% - 0.175rem)';
    left: 50%;
    background-color: currentColor;
  }

  &:before {
    transform: translate(-50%, 50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, 50%) rotate(45deg);
  }

  &:hover {
    background-color: currentColor;

    &:before,
    &:after {
      background-color: @white;
    }
  }

  &:active {
    color: @navBlaDarken40;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px @fokusFarge;
  }

  &--bla {
    color: @navBla;
  }

  &--hvit {
    color: @white;

    &:hover {
      background-color: @navLysBla;
      border-color: @navLysBla;
      color: @navGra60;

      &:before,
      &:after {
        background-color: @navMorkGra;
      }
    }
  }

  &--overstHjorne {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
