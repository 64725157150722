@import (reference) '~nav-frontend-paneler-style';

@import './assets/symbols';

.bekreftCheckboksPanel {
  .panel-mixin();
  .panel-focus-mixin();

  background-color: @orangeFocusLighten80;
  border-color: @orangeFocusLighten80;
  cursor: unset;
  transition: background-color linear 100ms;

  .bekreftCheckboksPanel__innhold {
    margin-bottom: 1rem;
  }

  .skjemaelement {
    margin-bottom: 0;
  }

  &--checked {
    background-color: @navGronnLighten80;
    border-color: @navGronnLighten80;

    .checkboks {
      + .skjemaelement__label {

        &:before {
          transition: none;
          background-color: @navGronn;
          border-color: @navGronn;
          background-image: @checkWhite;
        }

        &:hover {
          &:before {
            background-color: @navGronnLighten40;
            border-color: @navGronnLighten40;
          }
        }
      }

      &:focus {
        + .skjemaelement__label {
          &:before {
            border-color: @fokusFarge;
          }

          &:hover {
            &:before {
              background-image: @checkWhite;
            }
          }
        }
      }
    }
  }

  &--error {
  	background-color: @pinkErrorBg;
  	border: 1px solid transparent;
  }

}
